export const GARDEN_TYPE_ID_INDIVIDUAL = 1;
export const GARDEN_TYPE_ID_GROUP = 2;
export const GARDEN_TYPE_ID_PUBLIC = 3;
export const GARDEN_OPENING_TYPE_ID_STANDARD = 1;
export const GARDEN_OPENING_TYPE_ID_EVENING = 2;
export const GARDEN_OPENING_TYPE_ID_BY_ARRANGEMENT_ONLY = 3;
export const GARDEN_OPENING_TYPE_ID_ALSO_BY_ARRANGEMENT = 4;
export const GARDEN_OPENING_TYPE_ID_OPENING_WITHIN_GROUP = 5;
export const GARDEN_OPENING_TYPE_ID_GOP_NO_NGS_DATES = 6;
const FILTER_DAYS_OPEN_LABEL = 'days_open';
const FILTER_DAYS_OPEN_OPTIONS = 'next-28-days';
const FILTER_OPENING_TYPE_LABEL = 'opening_type';
const FILTER_OPENING_TYPE_OPTIONS = 'opening';

export const GARDEN_OPENING_TYPE_IDS_OPENING = [
  GARDEN_OPENING_TYPE_ID_STANDARD,
  GARDEN_OPENING_TYPE_ID_EVENING,
  GARDEN_OPENING_TYPE_ID_OPENING_WITHIN_GROUP,
  GARDEN_OPENING_TYPE_ID_GOP_NO_NGS_DATES,
];

export const GARDEN_OPENING_TYPE_IDS_ARRANGEMENT = [
  GARDEN_OPENING_TYPE_ID_BY_ARRANGEMENT_ONLY,
  GARDEN_OPENING_TYPE_ID_ALSO_BY_ARRANGEMENT,
];
const filterDaysOpen = {};
Object.defineProperty(filterDaysOpen, 'filter', {
  value: FILTER_DAYS_OPEN_LABEL,
  writable: false,
  enumerable: true,
  configurable: true,
});
Object.defineProperty(filterDaysOpen, 'options', {
  value: FILTER_DAYS_OPEN_OPTIONS,
  writable: false,
  enumerable: true,
  configurable: true,
});
const filterOpeningType = {};
Object.defineProperty(filterOpeningType, 'filter', {
  value: FILTER_OPENING_TYPE_LABEL,
  writable: false,
  enumerable: true,
  configurable: true,
});
Object.defineProperty(filterOpeningType, 'options', {
  value: FILTER_OPENING_TYPE_OPTIONS,
  writable: false,
  enumerable: true,
  configurable: true,
});
export const DEFAULTFILTERS = [
  filterDaysOpen,
  filterOpeningType,
]
