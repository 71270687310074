import {
  clone,
  concat,
  each,
  filter,
  find,
  first,
  includes,
  isArray,
  omit,
  sumBy,
} from 'lodash'
import api from '@/api/endpoints/filter'

import { DEFAULTFILTERS } from '@/constants'

export default {
  namespaced: true,

  state: {
    selectedFilters: [
      {
        filter: 'days_open',
        options: 'next-28-days',
      },
      {
        filter: 'opening_type',
        options: 'opening',
      },
    ],
    dynamicFilterSections: null,
  },

  mutations: {
    addBoundsFilter(state, data) {
      state.selectedFilters.push(data)
    },
    removeTag(state, data) {
      if (includes(['date_range'], data.type)) {
        state.selectedFilters = filter(state.selectedFilters, function (selectedFilter) {
          return !includes(['date_open_from', 'date_open_to'], selectedFilter.filter)
        })
        state.selectedFilters.push(find(DEFAULTFILTERS, ['filter', 'days_open']))
      } else {
        state.selectedFilters = filter(state.selectedFilters, function (selectedFilter) {
          return selectedFilter.filter !== data.filter
        })
      }
    },
    updateTag(state, data) {
      if (includes(['date_range'], data.type)) {
        state.selectedFilters = filter(state.selectedFilters, function (selectedFilter) {
          return selectedFilter.filter !== 'days_open'
        })
      }
      if (includes(['days_open'], data.filter)) {
        state.selectedFilters = filter(state.selectedFilters, function (selectedFilter) {
          return !includes(['date_open_from', 'date_open_to'], selectedFilter.filter)
        })
      }

      const existingFilter = find(state.selectedFilters, ['filter', data.filter])

      if (existingFilter) {
        let option = data.options
        let optionExists = null
        if (includes(['date_range'], data.type)) {
          existingFilter.options = data.options
          state.selectedFilters = filter(state.selectedFilters, function (selectedFilter) {
            return selectedFilter.filter !== 'days_open'
          })
        } else {
          if (isArray(existingFilter.options)) {
            option = first(data.options)
            optionExists = find(existingFilter.options, function (sectionOption) {
              return sectionOption === option
            })
          }
          if (!isArray(existingFilter.options)) {
            optionExists = existingFilter.options === option
          }
          if (optionExists && !includes(['radio', 'text', 'toggle'], data.type) && !data?.initial) {
            if (!includes(['multi_button', 'defaulted_toggle'], data.type)) {
              existingFilter.options = filter(existingFilter.options, function (sectionOption) {
                return sectionOption !== option
              })
            } else {
              existingFilter.options = null
            }
            if (!existingFilter.options || !existingFilter.options.length) {
              state.selectedFilters = filter(state.selectedFilters, function (selectedFilter) {
                return selectedFilter.filter !== data.filter
              })
            }
          } else {
            if (includes(['defaulted_toggle', 'radio', 'multi_button', 'text', 'toggle'], data.type)) {
              if (includes(['text'], data.type) && !data.options.length) {
                state.selectedFilters = filter(state.selectedFilters, function (selectedFilter) {
                  return selectedFilter.filter !== data.filter
                })
              }
              existingFilter.options = data.options
              if (includes(['toggle'], data.type)) {
                state.selectedFilters = filter(state.selectedFilters, function (selectedFilter) {
                  return selectedFilter.filter !== data.filter
                })
              }
            } else {
              existingFilter.options = concat(existingFilter.options, data.options)
            }
          }
        }
      } else {
        state.selectedFilters.push(omit(data, ['type']))
      }
    },
    setDynamicFilterSections(state, sections) {
      state.dynamicFilterSections = sections
    },
    resetFiltersWithoutSearch(state, data) {
      const defaultResetFilters = [
        {
          filter: 'days_open',
          options: 'next-28-days',
        },
        {
          filter: 'opening_type',
          options: 'opening',
        },
      ]
      const gardenFilter = find(state.selectedFilters, ['filter', 'garden'])
      if (gardenFilter) {
        defaultResetFilters.push(gardenFilter)
      }
      if (data.hasSelectedSearchResult || gardenFilter) {
        const geoBoundingBoxFilter = find(state.selectedFilters, ['filter', 'geo_bounding_box'])
        defaultResetFilters.push(geoBoundingBoxFilter)
      }
      state.selectedFilters = defaultResetFilters
    },
    resetFilters(state) {
      state.selectedFilters = [
        {
          filter: 'days_open',
          options: 'next-28-days',
        },
      ]
    },
    clearFilters(state) {
      state.selectedFilters = [
        {
          filter: 'days_open',
          options: 'next-28-days',
        },
        {
          filter: 'opening_type',
          options: 'opening',
        },
      ]
    },
    clearState(state) {
      state.dynamicFilterSections = []
      state.selectedFilters = [
        {
          filter: 'days_open',
          options: 'next-28-days',
        },
      ]
    },
  },

  actions: {
    clearFilters: ({ commit }) => new Promise((resolve) => {
      commit('clearFilters')
      resolve()
    }),
    clearState: ({ commit }) => new Promise((resolve) => {
      commit('clearState')
      resolve()
    }),
    fetchAllFiters: ({ commit }) => new Promise((resolve, reject) => {
      api.all((response) => {
        const responseData = response.data
        commit('setDynamicFilterSections', responseData)
        resolve(response)
      }, (error) => reject(error))
    }),
    updateTag: ({ commit }, newFilter) => new Promise((resolve) => {
      if (includes(['date_range'], newFilter.type)) {
        each(newFilter.options, function (rangeDate, rangeKey) {
          commit('updateTag', {
            filter: rangeKey,
            type: 'date_range',
            options: rangeDate,
          })
        })
      } else {
        commit('updateTag', newFilter)
      }
      resolve()
    }),
    removeFilter: ({
      commit,
      dispatch,
      rootState,
      getters,
    }, filterToRemove) => new Promise((resolve) => {
      commit('removeTag', filterToRemove)

      const hasSelectedSearchResult = rootState.location.selectedLocation || getters.hasGardenFilter ? true : false
      dispatch('garden/filterGardens', { fromMap: hasSelectedSearchResult }, { root: true })
      resolve()
    }),
    addFilter: ({
      commit,
      dispatch,
      rootState,
      getters,
      state,
    }, newFilter) => new Promise((resolve) => {
      if (includes(['date_range'], newFilter.type)) {
        each(newFilter.options, function (rangeDate, rangeKey) {
          commit('updateTag', {
            filter: rangeKey,
            type: 'date_range',
            options: rangeDate,
          })
        })
      } else {
        if (newFilter.filter === 'open_status' && newFilter.options === 'no-upcoming-openings') {
          const existingOpenStatusFilter = find(state.selectedFilters, ['filter', 'open_status'])
          let openDays = { filter: 'days_open', type: 'radio', options: 'all' }
          if (existingOpenStatusFilter && existingOpenStatusFilter.options === 'no-upcoming-openings') {
            openDays = { filter: 'days_open', type: 'radio', options: 'next-28-days' }
          }
          commit('updateTag', openDays)
        }
        commit('updateTag', newFilter)
      }

      const hasSelectedSearchResult = rootState.location.selectedLocation || getters.hasGardenFilter ? true : false
      dispatch('garden/filterGardens', { fromMap: hasSelectedSearchResult }, { root: true })
      resolve()
    }),
    resetFilters: ({ commit }) => new Promise((resolve) => {
      commit('resetFilters')
      resolve()
    }),
    resetFiltersAndGardens: ({ commit, dispatch, rootState }) => new Promise((resolve) => {
      const hasSelectedSearchResult = rootState.location.selectedLocation ? true : false

      commit('resetFiltersWithoutSearch', { hasSelectedSearchResult })
      dispatch('garden/filterGardens', [{}], { root: true })
      resolve()
    }),
    addBoundsFilter: ({ commit, dispatch }, bounds) => new Promise((resolve) => {
      const boundsFilter = {
        filter: 'geo_bounding_box',
        type: 'text',
        options: `${bounds.top_right.lat},${bounds.top_right.lng},${bounds.bottom_left.lat},${bounds.bottom_left.lng}`,
      }

      commit('updateTag', boundsFilter)
      dispatch('garden/filterGardens', { fromMap: true }, { root: true })
      resolve()
    }),
  },

  getters: {
    selectedFilters: (state) => state.selectedFilters,

    defaultFilters: () => DEFAULTFILTERS,

    dynamicFilterSections: (state) => state.dynamicFilterSections,

    defaultFiltersQuery: () => {
      const filters = {
      }
      each(DEFAULTFILTERS, function (defaultFilter) {
        const filterName = defaultFilter.filter === 'garden' ? 'garden_name' : defaultFilter.filter
        filters[`filter[${filterName}]`] = clone(defaultFilter.options)
      })
      return filters
    },

    selectedFiltersQuery: (state, getters) => {
      const filters = {}
      each(getters.selectedFilters, function (selectedFilter) {
        const filterName = selectedFilter.filter === 'garden' ? 'garden_name' : selectedFilter.filter
        filters[`filter[${filterName}]`] = selectedFilter.options
      })
      return filters
    },

    selectedFiltersQueryCheck: (state, getters) => {
      const filters = {}
      each(getters.selectedFilters, function (selectedFilter) {
        const filterName = selectedFilter.filter === 'garden' ? 'garden_name' : selectedFilter.filter
        filters[`filter[${filterName}]`] = (isArray(selectedFilter.options) && selectedFilter.options.length === 1) ? first(selectedFilter.options) : selectedFilter.options
      })
      return filters
    },
    hasGardenFilter: (state, getters) => {
      return getters.sectionFilterSelected('garden').length ? true : false
    },
    filterTypeByArray: (state) => (arrayOfLabels) => {
      return state.dynamicFilterSections ? filter(state.dynamicFilterSections, function (filterType, filterTypeIndex) {
        return includes(arrayOfLabels, filterTypeIndex)
      }) : []
    },

    dynamicFilterSectionByOpeningType: (state, getters) => {
      return getters.dynamicFilterSectionByKey('opening_type')
    },

    dynamicFilterSectionByKey: (state) => (key) => {
      return state.dynamicFilterSections ? find(state.dynamicFilterSections, function (dynamicFilterSection, dynamicFilterSectionIndex) {
        return dynamicFilterSectionIndex === key
      }) : null
    },

    dynamicFiltersOptionsByLabel: (state, getters) => (label) => {
      return getters.filterTypeBySlug(label) ? getters.dynamicFilterSectionByLabel(label).options : null
    },

    filtersBySlugAndKey: (state, getters) => (slug, key) => {
      return filter(getters.filtersBySlug(slug), ['filter_key', key])
    },
    filterSelected: (state) => (filterSection, option) => {
      const foundFilterSection = find(state.selectedFilters, ['filter', filterSection]) ?? null
      if (foundFilterSection) {
        if (isArray(foundFilterSection.options)) {
          return find(foundFilterSection.options, function (sectionOption) {
            return sectionOption === option
          }) ? true : false
        }
        if (!isArray(foundFilterSection.options)) {
          return foundFilterSection.options === option ? true : false
        }
      }
      return false
    },
    sectionFilterSelected: (state) => (filterSection) => {
      const foundFilterSection = find(state.selectedFilters, ['filter', filterSection]) ?? null
      if (foundFilterSection) {
        return foundFilterSection.options
      }
      return []
    },

    selectedFilterOptionsBytypeAndFilterIds: (state, getters) => (sectionType, filterIdToGet) => {
      const filterToFilter = find(getters.filterSectionBySectionType(sectionType), ['id', filterIdToGet])

      return filter(filterToFilter.options, function (option) {
        return option.selected
      }) ?? []
    },

    selectedFilterOptionsCount: (state) => {
      return sumBy(state.selectedFilters, function (filterSection) {
        if (isArray(filterSection.options)) {
          return filterSection.options.length
        }
        return 1
      })
    },
  },
}
